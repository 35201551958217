import { LoaderSpinner } from '../../loader-spinner/LoaderSpinner'
import s from './FormButton.module.scss'

interface IFormButton {
  isLoading?: boolean
  disabled?: boolean
}

export const FormButton = ({ isLoading = false, disabled = false }: IFormButton) => {
  return (
    <button
      className={`${s.form_button} ${disabled ? s.disabled : null}`}
      disabled={disabled}
      type="submit"
    >
      {isLoading && <LoaderSpinner />}
      <span>Next</span>
    </button>
  )
}
