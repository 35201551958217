import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useParams } from 'react-router-dom'

import s from './ResetPassword.module.scss'

import { FormButton } from '../../../../common/shared-components/buttons/form-button/FormButton'
import { ROUTES } from '../../../../common/router/routes'
import { ResetPasswordDto } from '../../../../auth/dto/reset-password.dto'
import { setToken } from '../../../../auth/auth.slice'
import { LocalStorageUtils } from '../../../../common/local-storage-utils'
import { useAppDispatch } from '../../../../common/store'
import { useResetPasswordMutation } from '../../../../auth/auth.api'
import { notificationCenterActions } from '../../../../common/shared-components/notification-center/data/notification-center.slice'
import { parseNotificationError } from '../../../../common/shared-components/notification-center/data/helpers/parse-notification-error'
import { captureError } from '../../../../common/monitoring/captureError'

export function ResetPassword() {
  const { userId = '', resetToken = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const RestoreSchema = Yup.object().shape({
    password: Yup.string().required("Field can't be empty"),
    confirmPassword: Yup.string()
      .required("Field can't be empty")
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })

  const onSubmit = async (values: ResetPasswordDto) => {
    try {
      const accessTokenDto = await resetPassword({
        userId,
        resetToken,
        password: values.password,
      }).unwrap()
      dispatch(
        notificationCenterActions.show({
          type: 'information',
          title: `Password changed`,
        }),
      )
      dispatch(setToken(accessTokenDto.accessToken))
      LocalStorageUtils.saveAccessToken(accessTokenDto.accessToken)
      LocalStorageUtils.setIsOldUser()
      navigate('/')
    } catch (e) {
      console.error(e)
      dispatch(
        notificationCenterActions.show({
          type: 'error',
          title: `Error when resetting the password`,
          description: parseNotificationError(e),
        }),
      )
      captureError(e)
    }
  }

  return (
    <div className={s.reset}>
      <div className={s.title}>Reset password</div>
      <div className={s.login__form_wrap}>
        <Formik
          initialValues={{
            userId,
            resetToken,
            password: '',
            confirmPassword: '',
          }}
          validationSchema={RestoreSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <div className={s.input__wrap}>
                <Field
                  className={s.input}
                  type="password"
                  name="password"
                  placeholder={'New Password'}
                />
                {errors.password && touched.password && (
                  <div className={s.error}>{errors.password}</div>
                )}

                <Field
                  className={s.input}
                  type="password"
                  name="confirmPassword"
                  placeholder={'Confirm Password'}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className={s.errorConfirmPass}>{errors.confirmPassword}</div>
                )}
              </div>
              <div className={s.form__btn}>
                <FormButton disabled={!isValid || isLoading} isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={s.accounted}>
        Return to{' '}
        <Link to={ROUTES.login.path}>
          <span className={s.link}>Sign In</span>
        </Link>
      </div>
    </div>
  )
}
